<template>
  <div class="order-base__warp audit">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <div class="erp-primary__btn" @click="toAudit" v-if="formAuditData.needAudit && hasPermission('erp:payment:auditFmPayment')">审核</div>
      <div class="erp-primary__btn" @click="toEdit" v-if="formAuditData.needAudit && hasPermission('erp:payment:savePayment')">编辑</div>
      <div class="erp-primary__btn" @click="toCancel" v-if="formAuditData.needAudit && hasPermission('erp:payment:savePayment')">取消付款</div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <div style="margin-top: 10px" class="order-title__bar op-16">
      <div class="erp-form__title f16">付款订单</div>
      <div class="edit-btn__bar">
        <span>编辑人：{{ filterName(formAuditData.lastEditOperator) }}</span>
        <span>编辑时间：{{ filterName(formAuditData.lastEditTime) }}</span>
        <span>创建时间：{{ formAuditData.createdTime }}</span>
        <span>创建人：{{ formAuditData.creator }}</span>
        <span v-if="formAuditData.auditTime">审核时间：{{ formAuditData.auditTime }}</span>
        <span v-if="formAuditData.auditOperator">审核人：{{ formAuditData.auditOperator }}</span>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :show-overflow-tooltip="true" :model="formAuditData">
        <div class="">
          <div class="claim_company">
            <el-table
              :data="formAuditData.erpPaymentOrderVos"
              border
              class="audit audit-detail egrid erp-table__list erp-view__table"
              :show-summary="formAuditData.erpPaymentOrderVos.length > 1"
              :summary-method="getSummaries"
            >
              <el-table-column
                label="销售合同号"
                class-name="no-pd table-visble"
                prop="businessContractNo"
                align="center"
                :show-overflow-tooltip="true"
                width="180"
              >
                <template #default="scope">
                  <div :class="{'record__link':scope.row.businessContractNo}" @click="linkTo(scope.row)">{{ filterName(scope.row.businessContractNo) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="签约主体" class-name="no-pd table-visble" prop="principalName" align="center" show-overflow-tooltip width="110">
                <template #default="scope">
                  <div>{{ filterName(scope.row.principalName) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="付款类型" class-name="no-pd table-visble" prop="paymentTypeDesc" align="center" :show-overflow-tooltip="true" width="100">
                <template #default="scope">
                  <div>{{ filterName(scope.row.paymentTypeDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="结算单号" class-name="no-pd table-visble" prop="buyStmtNo" align="center" show-overflow-tooltip width="110">
                <template #default="scope">
                  <div>{{ filterName(scope.row.buyStmtNo) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="杂费类型" class-name="no-pd table-visble" prop="sundryFeeTypeDesc" align="center" :show-overflow-tooltip="true">
                <template #default="scope">
                  <div>{{ filterName(scope.row.sundryFeeTypeDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="结算金额" class-name="no-pd table-visble" prop="expectAmount" align="center" :show-overflow-tooltip="true" width="100">
                <template #default="scope">
                  <div>{{ filterName(scope.row.expectAmountDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="申请付款金额"
                class-name="no-pd table-visble"
                prop="applyPayAmount"
                align="center"
                :show-overflow-tooltip="true"
                width="130"
              >
                <template #default="scope">
                  <div>{{ filterName(scope.row.applyPayAmountDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="实际付款金额"
                class-name="no-pd table-visble"
                prop="actualPayAmount"
                align="center"
                :show-overflow-tooltip="true"
                width="130"
              >
                <template #default="scope">
                  <div>{{ filterName(scope.row.actualPayAmountDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="actPayDiffAmountDesc" class-name="no-pd table-visble" align="center" :show-overflow-tooltip="true" width="120">
                <template #header>
                  <div>
                    <span>实收实付差额</span>
                    <el-tooltip placement="top">
                      <template #content>
                        <div>实收实付差额：假设本次付款成功，当前合同的<br />总实收金额减去总实付金额的差额</div>
                      </template>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="备注" class-name="no-pd table-visble" prop="remark" align="center" :show-overflow-tooltip="true">
                <template #default="scope">
                  <div>{{ filterName(scope.row.remark) }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="no-pd table-visble required-icon" width="100">
                <template #header>
                  <span>凭据文件</span>
                </template>
                <template #default="scope">
                  <tableFileContent
                    :readOnly="true"
                    :fileUrlsArray="scope.row.fileVoList"
                    key-name="orderArapFileName"
                    key-url="orderArapFileUrl"
                    key-id="orderArapFileId"
                  >
                  </tableFileContent>
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="no-pd table-visble required-icon" width="120">
                <template #header>
                  <span>上游业务合同</span>
                </template>
                <template #default="scope">
                  <tableFileContent
                    :readOnly="true"
                    :fileUrlsArray="scope.row.attachmentDtos"
                    key-name="name"
                    key-url="url"
                    key-id="id"
                  >
                  </tableFileContent>
                </template>
              </el-table-column>
              <el-table-column label="审核操作" align="center" class-name="no-pd table-visble required-icon">
                <template #default="scope">
                  <div>
                    <span>{{ scope.row.auditStatusDesc }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="审核说明" align="center" class-name="no-pd table-visble" :show-overflow-tooltip="true" width="220">
                <template #default="scope">
                  <div>
                    <span>{{ filterName(scope.row.auditDesc) }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16" style="margin-top: 30px">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">付款账号</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="收款方类型" :value="formAuditData.payeeTypeDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款方名称" :value="formAuditData.accountName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款方账号" :value="formAuditData.accountNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开户银行名称" :value="formAuditData.bankName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开户行支行名称" :value="formAuditData.branchName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开户行所在城市" :value="cityName"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 侧边栏弹窗展示 -->
    <erp-drawer :visible="dialog.visible" @close="closeDialog" erp class="no-padding" width="1100">
      <edit-form :formType="formType" :editFormId="editFormId" @success="successCallback" @cancel="cancelCallBack"></edit-form>
    </erp-drawer>
  </div>
</template>
<script>
import editForm from './form.vue';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { getRouterParams, loadPage } from '@/utils/publicMethods';
export default {
  components: { editForm, tableFileContent },
  data() {
    return {
      formType: 2,
      dialog: {
        title: '申请付款',
        visible: false,
      },
      editFormId: null,
      formAuditData: {
        lastEditOperator: null,
        lastEditTime: null,
        needAudit: null,
        id: '',
        paymentId: null,
        accountName: '',
        accountNo: '',
        bankId: '',
        bankName: '',
        branchName: '',
        addressProvince: '',
        addressProvinceId: '',
        addressCity: '',
        addressCityId: '',
        createdTime: '',
        creator: '',
        version: '',
        payeeTypeDesc: '',
        erpPaymentOrderVos: [
          {
            id: '',
            orderId: '',
            businessContractNo: '',
            buyStmtNo: '',
            paymentTypeName: '',
            sundryFeeTypeName: '',
            orderArapDetailId: '',
            expectAmount: '',
            applyPayAmount: '',
            remark: '',
            attachmentFileName: '',
            attachmentFileUrl: '',
            contractFileName: '',
            contractFileUrl: '',
            auditStatus: '', // 通过取值获取 调试默认给通过
            auditDesc: '',
          },
        ],
      },
      isMunicipality: false, // 是否直辖市
      municipalityArr: ['北京市', '上海市', '天津市', '重庆市'],
      showType: 2, // 1,审核付款，2 查看付款
      editData: {},
    };
  },
  computed: {
    cityName() {
      const proName = this.formAuditData.addressProvince;
      let city = '';
      if (this.municipalityArr.includes(proName)) {
        // this.isMunicipality = true;
        city = this.formAuditData.addressProvince;
      } else {
        // this.isMunicipality = false;
        city = this.formAuditData.addressCity;
      }
      return city;
    },
  },
  created() {
    const { id, version } = getRouterParams('paymentDetail');
    this.formAuditData.version = version;
    this.version = version;
    this.getFmPaymentDetail(id);
  },
  methods: {
    linkTo(row){
      loadPage('OrderDetail', {
        orderId: row.orderId,
        version: row.version,
        name: 'payment',
      });
    },
    successCallback() {
      this.dialog.visible = false;
      this.getFmPaymentDetail(this.$route.params.id);
    },
    cancelCallBack(isRefresh) {
      this.dialog.visible = false;
      if (isRefresh) {
        const { id, version } = getRouterParams('paymentDetail');
        this.formAuditData.version = version;
        this.getFmPaymentDetail(id);
      }
    },
    getFmPaymentDetail(id) {
      this.ajax({
        type: 'GET',
        url: 'malicrm/payment/paymentDetail/' + id,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formAuditData = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 取消
    toCancel() {
      const param = Object.assign({}, this.formAuditData);
      param.operateType = 4;
      param.paymentOrderDtoList = this.formAuditData.erpPaymentOrderVos;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.$erpConfirm('确定取消本次付款吗？').then(() => {
        this.ajax({
          type: 'POST',
          url: '/malicrm/payment/savePayment',
          data: param,
          success: (res) => {
            if (res.code === 200) {
              that.successTip('已取消', 3000);
              const { id, version } = getRouterParams('paymentDetail');
              that.formAuditData.version = version;

              that.getFmPaymentDetail(id);
            } else {
              that.errorTip(res.message);
            }
          },
        });
      });
    },
    closeDialog() {
      this.dialog.visible = false;
    },
    // 编辑
    toEdit() {
      this.editFormId = this.formAuditData.id;
      this.formType = 2;
      this.dialog.visible = true;
      this.dialog.title = '申请付款';
    },
    // 审核
    toAudit() {
      const orderId = this.formAuditData.id;
      const version = this.formAuditData.version;

      loadPage('paymentAudit', {
        id: orderId,
        version,
      });
    },
    validOrder() {
      let result = true;
      for (let i = 0; i < this.formAuditData.erpPaymentOrderVos.length; i++) {
        const obj = this.formAuditData.erpPaymentOrderVos[i];
        if (obj.auditStatus === 1 || obj.auditStatus === 4 || obj.auditStatus === '') {
          result = false;
          this.errorTip('请进行审核操作');
          return result;
        }
        if (obj.auditStatus === 3 && !obj.remark) {
          result = false;
          this.errorTip('审核不通过请说明原因');
          return result;
        }
      }
      return result;
    },
    submit() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formAuditData));
      const isValid = this.validOrder();
      if (!isValid) {
        return;
      }
      this.ajax({
        type: 'POST',
        url: '/malicrm/payment/auditFmPayment',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('提交成功', 3000);
            this.$router.push('/erp/financial/payment');
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      this.$router.push('/erp/financial/payment');
    },
    showOnline(name, url) {
      this.$showFiles(name, url);
    },
    getSummaries(param) {
      console.log('param', param);
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        //4 是结算金额不显示结算金额
        const noshow = [1, 2, 3, 4, 7, 8, 9, 10, 11];
        if (noshow.includes(index)) {
          sums[index] = '';
          return;
        }
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (~String(sums[index]).indexOf('.')) {
            sums[index] = parseFloat(sums[index]).toFixed(2);
          }
          sums[index] = String(sums[index]).replace(reg, '$1,');
          if (Number(sums[index]) === 0) {
            sums[index] = '';
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;

  span {
    margin-left: 24px;
  }
}
.el-icon-question {
  font-size: 16px !important;
}
.el-icon-question:hover {
  color: #2878ff;
}

.erp-table__list.el-table th {
  padding: 0;
}

.el-radio {
  margin-right: 20px;
}

.radio-group-c {
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

.color-link {
  cursor: pointer;
  color: #2878ff;
}

.el-table--small th {
  padding: 0;
}

.no-pd {
  padding: 0;
}
.order-base__warp.audit .el-table .table-visble .cell {
  max-width: 700px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
